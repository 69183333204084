<template>
	<v-container fluid ma-0 pa-0>
	<v-toolbar flat color="blue-grey darken-1">
		<v-text-field v-model="filter" flat solo dense hide-details background-color="blue-grey darken-2" 
		prepend-inner-icon="search" append-icon="mdi-keyboard-return" clearable @click:clear="onSearchClear()"></v-text-field>  
		<v-btn class="mx-1 mt-0" color="blue-grey darken-4" @click.stop="onSearch()">Suchen</v-btn>
		<v-spacer></v-spacer>
		<v-spacer></v-spacer>
		<v-btn color="blue-grey darken-4" @click.stop="onEditItem()">Neu</v-btn>
	</v-toolbar>
    <v-data-table
		:headers="cfgLists"
		:items="filteredItems"
		item-key="id"
		:page.sync="page"
		:items-per-page="10"
		must-sort
		:sort-by.sync="sortBy"
		:sort-desc.sync="sortDesc"
		:custom-sort="customSort"
		class="blue-grey darken-3 table-cursor"
		hide-default-footer
		:loading="loading"
		@click:row="onRowClick($event)"
    >
		<!--<template v-slot:body="props">
			<tr v-for="(item, index) in props.items" :key="index">
				<td v-for="(header, hindex) in cfgLists" :key="hindex">
					X{{ formatCell(item[header.value], header.format) }}
				</td>
			</tr>
		</template>-->

		<template v-slot:item.id="{ item }">
			<v-chip color="blue-grey" dark x-small>{{ item.id }}</v-chip>
		</template>
		<template v-slot:item.action="{ item }">
			<v-hover v-slot:default="{ hover }">
			<v-btn small fab color="blue-grey darken-4" :class="{ 'success': hover }" @click.stop="onEditItem(item.id)" :loading="false" :disabled="false">
				<v-icon>create</v-icon></v-btn></v-hover>
			<v-hover v-slot:default="{ hover }">
			<v-btn small fab color="blue-grey darken-4" :class="{ 'warning': hover }" @click.stop="onPrintItem(item.id)" :loading="false" :disabled="false">
				<v-icon small>print</v-icon></v-btn></v-hover>
		</template>
		<template slot="footer">
			<div class="text-xs-center pt-2">
				<v-pagination color="blue-grey" v-model="page" :length="pages" :total-visible="7" @input="onPageChange"></v-pagination>
			</div>
		</template>
    </v-data-table>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
    <GenericForm v-if="edit" :tablename="tablename" :reftable="modulData.meta.tablename" :model="item" @cancelled="editCancelled()" @submitted="editSubmitted()" />
	</v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import GenericForm from './GenericForm'
export default {
	name: 'GenericList',
	components: {
		GenericForm,
	},
	props: {
		modulData: {
			type: Object,
			required: true,
			default: null,
		},
	},
	data: () => ({
		loading: false,
		page: 1,
		pages: 1,
		sortBy: 'id',
		sortChangeTime: null,
		sortDesc: true,
		filter: '',
		cfgTable: {},
		cfgLists: [],
		items: [],
		item: {},
		edit: false,
    }),
	computed: {
		filteredItems: function () {
			let self = this
			if(!self.items.length) {return []}
			if(!self.searchQuery || self.searchQuery.length < 3) {
				return self.items
			} else {
				return self.items.filter(function (item) {
					let searchRegex = new RegExp(self.searchQuery, 'i')
					return searchRegex.test(item.name)
				})
			}
		},
		tablename() {
			return this.modulData.meta.subtable
		},
	},
	methods: {
		formatItems(list) {
			return list.map(item => {
				//const obj = Object.assign({}, item);
				this.cfgLists.forEach(e => {
					item[e.value] = this.formatItem(item[e.value], e.format)
				})
				return item
				//obj.datum = formatItem(obj.datum, format)
				//return obj
			})
		},
		formatItem(item, format) {
			switch(format) {
				case 'date':
					if (!item || item.search("-") < 0) return ''
					const [year, month, day] = item.split('-')
					const d = new Date(year, month - 1, day);
					return d.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", })
					break
				default:
					return item
					break
			}
		},
		customSort: function(items, index, isDesc) {
			return items;
		},
		async getData() {
            this.loading = true
			let param = {
				tablename: this.modulData.meta.subtable,
				parentref: this.modulData.meta.refname,
				parentid: this.modulData.params.id,
				page: this.page,
				sortBy: this.sortBy,
				sortDesc: (this.sortDesc?'desc':'asc'),
				filter: this.filter,
			}
            try {
                await this.$store.dispatch('generic/getItems', param)
                const result = this.$store.getters['generic/getItems']( this.tablename )
                this.items = this.formatItems(result.data)
				this.page = result.current_page
				this.pages = result.last_page
            } catch (error) {
                console.error(error)
                this.message.title = error.name
                this.message.msg = '<code>' + JSON.stringify(error.message).substring(0, 200) + '</code>'
                this.showmessage = true
            } finally {
                this.loading = false
            }
		},
		getCfgs() {
			let param = {
				table_name: this.modulData.meta.subtable,
				reftable: this.modulData.meta.tablename,
			}
			if(this.reftable == 'none') { param.reftable = this.tablename }
			this.cfgTable = this.$store.getters['core/getCfgTable'](param)
			this.cfgLists = this.$store.getters['core/getCfgLists'](param)
			//this.cfgLists.push({ text: 'Open', value: 'open', align: 'center', sortable: false, width: 25, })
			this.cfgLists.push({ text: 'Action', value: 'action', align: 'center', sortable: false, width: 25, })
			console.log('genericList cfgTable:', this.cfgTable)
			console.log('genericList cfgLists:', this.cfgLists)
		},
		onSearch () {
			this.getData()
			console.log(this.items)
			console.log(this.filteredItems)
		},
		onSearchClear () {
			this.filter = ''
			this.getData()
		},
		onPageChange () {
			this.getData()
		},
		onPrintItem (index) {
			alert(index)
		},
		onEditItem (id = null) {
			if(id && id >= 0) {
				this.item = Object.assign({}, this.filteredItems.find(x => x.id === id))
				console.log("XMODEL:", this.item)
			} else {
				this.item = {}
				this.item[this.modulData.meta.refname] = this.modulData.params.id
				this.item[this.modulData.name] = this.modulData.meta.item
			}
			this.edit = true
		},
		editCancelled () {
			this.edit = false
			this.snack = {active: true, type: 'warning', text: 'cancelled'}
		},
		editSubmitted() {
			this.getData()
			this.edit = false
			this.snack = {active: true, type: 'success', text: 'saved'}
		},
		onSortChange () {
			this.$nextTick(() => {
				this.getData()
			})
		},
		onRowClick(e) {
			console.log('clicked:', e)
			//const item = e
			//this.$router.push({ name: this.cfgTable.model_name, params: { id: item.id } })
		},
	},
	mounted() {
		this.getCfgs()
		this.getData()
	},
	watch: {
		sortBy () {
			if(Date.now() - this.sortChangeTime > 50) setTimeout(this.onSortChange (), 50)
			this.sortChangeTime = Date.now()
		},
		sortDesc () {
			if(Date.now() - this.sortChangeTime > 50) setTimeout(this.onSortChange (), 50)
			this.sortChangeTime = Date.now()
		},
	},
};
</script>
<style scoped>
.selected {
    background-color: red
}
.table-cursor >>> tbody tr:hover {
	cursor: pointer;
}
</style>